import './ErrorPage.scss';
import { Button, Col, Row } from 'react-bootstrap';
import React from 'react';

export interface EventDetailsProps {
  fromError: boolean;
}

const ErrorPage: React.FunctionComponent<EventDetailsProps> = ({ fromError = false }) => {
  const content = fromError ? (
    <>
      <h1>OOPS</h1>
      <p>There&apos;s been an error. Please contact us and let us know.</p>
    </>
  ) : (
    <>
      <h1>404</h1>
      <p>The page you&apos;re looking for doesn&apos;t exist</p>
      <div className="my-4">
        <Button href="/" className="px-5 py-3 rounded-5" variant="primary">
          Go back home
        </Button>
      </div>
    </>
  );
  return (
    <Row className="error-container justify-content-between">
      <Col xs={12} xl={5} className="d-flex flex-column justify-content-center text-center text-xl-start">
        {content}
      </Col>
      <Col xs={12} xl={'auto'} className="d-flex align-items-end justify-content-evenly text-center mt-3">
        <img src="/error.gif" />
      </Col>
    </Row>
  );
};

export default ErrorPage;
