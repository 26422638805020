import './Toolbar.scss';
import React from 'react';
import { Navigate as navigate, PropTypes } from 'react-big-calendar';
import { Button, Col, Row } from 'react-bootstrap';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

export interface ToolbarProps {
  label: PropTypes.string;
  localizer: PropTypes.object;
  messages: PropTypes.object;
  onNavigate: PropTypes.func;
}

const Toolbar: React.FunctionComponent<ToolbarProps> = ({ label, onNavigate }) => {
  return (
    <Row className="rbc-toolbar">
      <Col md={6} sm={12} className="rbc-toolbar-label">
        {label.slice(0, -4)} <span className="rbc-toolbar-year">{label.slice(-4)}</span>
      </Col>

      <Col md={6} sm={12} className="rbc-btn-group d-flex justify-content-md-end">
        <Button className="mx-0 rounded" onClick={() => onNavigate(navigate.TODAY)} aria-label="Today">
          Today
        </Button>
        <Button
          className="rbc-btn-prev ms-3 rounded d-flex align-items-center"
          onClick={() => onNavigate(navigate.PREVIOUS)}
          aria-label="Previous">
          <MdChevronLeft /> Prev
        </Button>
        <Button
          className="rbc-btn-next ms-3 rounded d-flex align-items-center"
          onClick={() => onNavigate(navigate.NEXT)}
          aria-label="Next">
          Next <MdChevronRight />
        </Button>
      </Col>
    </Row>
  );
};

export default Toolbar;
