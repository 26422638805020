import './Layout.scss';
import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../Components/Header/Header';

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <Container>
      <Header />
      <div>{children}</div>
    </Container>
  );
};

export default Layout;
