import './App.scss';
import React from 'react';
import { AppRouter } from './AppRouter';

export default class App extends React.Component<object> {
  render() {
    return (
      <div className="App flex-column">
        <AppRouter />
      </div>
    );
  }
}
