import './Loader.scss';
import React from 'react';

export interface LoaderProps {}

const Loader: React.FunctionComponent<LoaderProps> = () => {
  return (
    <div className="loader">
      <div className="loader-img">
        <img src="/loader.gif" width="300" alt="loading..." />
      </div>
    </div>
  );
};

export default Loader;
