import './EventCalendar.scss';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import EventCalendarToolbar from './Toolbar/Toolbar';
import { calendarEvent } from '../../Models/calendarEvent';
import EventLineItem from './EventLineItem/EventLineItem';
import EventCompactItem from './EventCompactItem/EventCompactItem';

export interface EventCalendarProps {
  date: Date;
  events: calendarEvent[];
  calendarType: string;
  setDate: (date: { month: string; year: string }) => void;
}

const EventCalendar: React.FunctionComponent<EventCalendarProps> = ({ date, events, calendarType, setDate }) => {
  const localizer = momentLocalizer(moment);

  const formats = {
    dateFormat: (date, culture, localizer) => localizer.format(date, 'D', culture),
  };

  const components = {
    event: calendarType === 'compact' ? EventCompactItem : EventLineItem,
    toolbar: EventCalendarToolbar,
  };

  return (
    <div className={`calendar-container ${calendarType === 'compact' ? 'calendar-compact' : 'calendar-line'}`}>
      <Calendar
        date={date}
        localizer={localizer}
        events={events}
        components={components}
        views={['month']}
        formats={formats}
        onNavigate={newDate => setDate({ month: moment(newDate).format('MM'), year: moment(newDate).format('YYYY') })}
        popup
      />
    </div>
  );
};

export default EventCalendar;
