import './EventDetails.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { Alert, Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { eventDetails } from '../../Models/eventDetails';
import { EventTypes } from '../../Constants/eventtype';
import moment from 'moment';
import { HiCalendar, HiLocationMarker, HiUserCircle } from 'react-icons/hi';
import IconTile from '../../Components/IconTile/IconTile';
import { MdOutlineInventory } from 'react-icons/md';
import Loader from '../../Components/Loader/Loader';

export interface EventDetailsProps {}

const EventDetails: React.FunctionComponent<EventDetailsProps> = () => {
  const { event_type, event_number } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [isErrored, setError] = useState(false);
  const [eventDetails, setEventDetails] = useState<eventDetails>();

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}/${event_type}/${event_number}`,
      withCredentials: false,
    })
      .then(response => {
        setEventDetails(response.data as eventDetails);
      })
      .finally(() => setLoading(false))
      .catch(() => setError(true));
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {isErrored && <Navigate to="/error" replace={true} />}
      {isErrored && <></>}
      <div className="event-details">
        <Row className={`event-details-header mx-0 mb-3 p-4 bg-${EventTypes[event_type].color}`}>
          <Col className="mb-3">
            <p className="event-pretitle mb-4">
              #{event_number} / {eventDetails?.eventStatus}
            </p>
            <p>
              {eventDetails?.club?.website ? (
                <a href={eventDetails?.club?.website} target="_blank" rel="noreferrer">
                  {eventDetails?.club?.name}
                </a>
              ) : (
                eventDetails?.club?.name
              )}
            </p>
            <h1>{EventTypes[event_type].name}</h1>
            <p className="event-subtitle mt-3 d-flex gap-4">
              <span className="d-flex align-items-center gap-2">
                <HiCalendar />{' '}
                {eventDetails?.startDate
                  ? moment(eventDetails?.startDate, 'YYYY-MM-DD')
                      .format('DD MMM YYYY')
                      .toUpperCase()
                  : 'Not Available'}
              </span>
              <span className="d-flex align-items-center gap-2">
                <HiLocationMarker />
                {eventDetails?.site ? `${eventDetails?.site?.city}, ${eventDetails?.site?.state}` : 'Not Available'}
              </span>
            </p>
          </Col>
          <Col lg={'auto'} className="d-flex flex-column align-items-end justify-content-center">
            <label> Entry Fees</label>
            <p className="event-fee">
              {eventDetails?.entryFee?.map((fee, i, arr) => {
                if (i + 1 === arr.length) return `$${fee}`;
                else return `$${fee} | `;
              })}
            </p>
          </Col>
        </Row>
        <Row className="mx-0 p-4">
          <Col md={6} className="mb-3">
            <Row className="d-flex flex-nowrap">
              <Col xs={'auto'}>
                <IconTile>
                  <MdOutlineInventory />
                </IconTile>
              </Col>
              <Col>
                <label>Registration Opens</label>
                <p>
                  {eventDetails?.openingDate
                    ? moment(eventDetails?.openingDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                    : 'Not Available'}
                </p>
                <label>Registration Closes</label>
                <p>
                  {eventDetails?.closingDate
                    ? moment(eventDetails?.closingDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                    : 'Not Available'}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="mb-3">
            <Row className="d-flex flex-nowrap">
              <Col xs={'auto'}>
                <IconTile>
                  <HiLocationMarker />
                </IconTile>
              </Col>
              <Col>
                <label>Location</label>
                {eventDetails?.site ? (
                  <>
                    <p>{eventDetails?.site?.name}</p>
                    <p>{eventDetails?.site?.address1}</p>
                    <p>{eventDetails?.site?.address2}</p>
                    <p>
                      {eventDetails?.site?.city}, {eventDetails?.site?.state}
                    </p>
                  </>
                ) : (
                  <p>Not Available</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="mx-0 p-4">
          <Col>
            <Tabs variant="underline" defaultActiveKey="premium" className="mb-3">
              <Tab eventKey="premium" title="Premium">
                {eventDetails?.premium ? (
                  <object
                    data={
                      event_type === 'BH'
                        ? `https://www.barnhunt.com/attachments/${eventDetails?.premium}`
                        : `https://www.apps.akc.org/apps/eventplans/eventsearch/blocks/dsp_generate_pdf.cfm?KEY_BINARY_CONTENT=${eventDetails?.premium}#toolbar=0&navpanes=0&scrollbar=0`
                    }
                    type="application/pdf"
                    width="100%"
                    height="800">
                    <a
                      href={
                        event_type === 'BH'
                          ? `https://www.barnhunt.com/attachments/${eventDetails?.premium}`
                          : `https://www.apps.akc.org/apps/eventplans/eventsearch/blocks/dsp_generate_pdf.cfm?KEY_BINARY_CONTENT=${eventDetails?.premium}`
                      }
                      target="_blank"
                      rel="noreferrer">
                      Premium List
                    </a>
                  </object>
                ) : (
                  <Alert variant="light">
                    <Alert.Heading>Premium document not found</Alert.Heading>
                    {event_type == 'FCAT' || event_type == 'CABT' ? (
                      <p>
                        No premium was included for this event on the AKC site, though FastCATs and Coursing Ability
                        Tests are most likely hosted by{' '}
                        <a
                          className="alert-link"
                          href="https://www.sugarloafmountainracing.com/"
                          target="_blank"
                          rel="noreferrer">
                          Sugarloaf Mountain Racing
                        </a>
                        . Otherwise, please check the kennel club website.
                      </p>
                    ) : event_type == 'AG' ? (
                      <p>
                        No premium was included for this event on the AKC site, though Agility trials are most likely
                        available to enter via{' '}
                        <a
                          className="alert-link"
                          href="https://www.labtestedonline.com/"
                          target="_blank"
                          rel="noreferrer">
                          Lab Tested Online
                        </a>
                        . Otherwise, please check the kennel club website.
                      </p>
                    ) : event_type == 'BH' ? (
                      <p>
                        No premium was included for this event on the Barn Hunt site. Check the event details directly
                        on{' '}
                        <a
                          className="alert-link"
                          href={
                            'https://www.barnhunt.com/register/event.php?key=' +
                            event_number.substring(0, event_number.length - 8)
                          }
                          target="_blank"
                          rel="noreferrer">
                          Barn Hunt Association&apos;s official site
                        </a>
                        .
                      </p>
                    ) : (
                      <p>
                        No premium was included for this event on the AKC site. Please check the kennel club website.
                      </p>
                    )}
                  </Alert>
                )}
              </Tab>
              {event_type !== 'FCAT' && event_type !== 'CABT' && (
                <Tab eventKey="classes" title="Classes">
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Class</th>
                        {event_type !== 'BH' && <th>Judge</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {eventDetails?.classes?.map((eventClass, i) => (
                        <tr key={i}>
                          <td>{eventClass.class}</td>
                          {event_type !== 'BH' && <td>{eventClass.judge}</td>}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
        <hr />
        <Row className="mx-0 p-4">
          <Col md={6} className="mb-3">
            <Row className="d-flex flex-nowrap">
              <Col xs={'auto'}>
                <IconTile>
                  <HiUserCircle />
                </IconTile>
              </Col>
              <Col>
                <label>Secretary</label>
                {eventDetails?.superintendentSecretary ? (
                  <>
                    <p>{eventDetails?.superintendentSecretary?.name}</p>
                    <p>{eventDetails?.superintendentSecretary?.email}</p>
                    <p>{eventDetails?.superintendentSecretary?.phone}</p>
                  </>
                ) : (
                  <p>Not Available</p>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={6} className="mb-3">
            <Row className="d-flex flex-nowrap">
              <Col xs={'auto'}>
                <IconTile>
                  <HiUserCircle />
                </IconTile>
              </Col>
              <Col>
                <label>Event Chair</label>
                {eventDetails?.additionalContact ? (
                  <>
                    <p>{eventDetails?.additionalContact?.name}</p>
                    <p>{eventDetails?.additionalContact?.email}</p>
                    <p>{eventDetails?.additionalContact?.phone}</p>
                  </>
                ) : (
                  <p>Not Available</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EventDetails;
