import { Col, Row } from 'react-bootstrap';
import './Header.scss';
import React from 'react';

export interface HeaderProps {}

const Header: React.FunctionComponent<HeaderProps> = () => {
  return (
    <>
      <Row className="header py-4">
        <Col className="d-flex align-items-center">
          <h4 className="m-0 d-flex align-items-center gap-2">
            <img src="/logo512.png" width="40" />
            <a href="/">DMV Dog Events</a>
          </h4>
        </Col>
      </Row>
      <hr className="m-0" />
    </>
  );
};

export default Header;
