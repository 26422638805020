import './IconTile.scss';
import React from 'react';

export interface IconTileProps {
  children: React.ReactNode;
}

const IconTile: React.FunctionComponent<IconTileProps> = ({ children }) => {
  return <div className="icon-tile bg-light d-flex align-items-center">{children}</div>;
};

export default IconTile;
