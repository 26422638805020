import './EventCompactItem.scss';
import React from 'react';
import { EventTypes } from '../../../Constants/eventtype';
import moment from 'moment';

export interface EventCompactItemProps {
  event;
}

const EventCompactItem: React.FunctionComponent<EventCompactItemProps> = ({ event }) => {
  return (
    <div
      className={`rbc-event-compact rounded bg-${EventTypes[event.resource.type].color}${
        event.resource.data.items[0].closingDate < moment().valueOf() ? ' disabled' : ''
      }`}
      title={event.title}
      onClick={e => {
        e.stopPropagation();
        window.open(`/${event.resource.type}/${event.resource.data.eventNumber}`, '_blank');
      }}>
      {event.resource.data.city}, {event.resource.data.state}
    </div>
  );
};

export default EventCompactItem;
