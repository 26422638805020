import './Home.scss';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import EventCalendar from '../../Components/EventCalendar/EventCalendar';
import { Col, Dropdown, Row, ToggleButton } from 'react-bootstrap';
import { calendarEvent } from '../../Models/calendarEvent';
import { EventType, EventTypes } from '../../Constants/eventtype';
import { MdDensityMedium, MdFilterList, MdFormatAlignJustify } from 'react-icons/md';
import { CalendarType } from '../../Constants/calendartype';
import moment from 'moment';
import Loader from '../../Components/Loader/Loader';
import { Navigate } from 'react-router-dom';

export interface HomeProps {}

const Home: React.FunctionComponent<HomeProps> = () => {
  const isFirstRender = useRef(true);
  const [isLoading, setLoading] = useState(true);
  const [isErrored, setError] = useState(false);
  const [date, setDate] = useState<{ month: string; year: string }>({
    month: moment().format('MM'),
    year: moment().format('YYYY'),
  });
  const [eventsList, setEventsList] = useState<calendarEvent[]>([]);
  const [filteredEventsList, setFilteredEventsList] = useState<calendarEvent[]>([]);
  const [calendarType, setCalendarType] = useState<string>(CalendarType.Line);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}?month=${date.month}&year=${date.year}`,
      withCredentials: false,
    })
      .then(response => {
        setEventsList(
          response.data
            .reduce((unique, o) => {
              if (
                !unique.some(
                  obj => obj.eventName === o.eventName && obj.startDate === o.startDate && obj.endDate === o.endDate
                )
              ) {
                unique.push(o);
              }
              return unique;
            }, [])
            .map(e => {
              return {
                title: e.eventName,
                start: moment(e.startDate, 'YYYY-MM-DD').toDate(),
                end: moment(e.endDate, 'YYYY-MM-DD').toDate(),
                resource: {
                  type: EventType[e.eventType],
                  data: e,
                },
              } as calendarEvent;
            })
            .sort((a, b) => (a.resource.type > b.resource.type ? 1 : -1))
        );
      })
      .finally(() => setLoading(false))
      .catch(() => setError(true));
  }, [date]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    filters.length === 0 || filters.length === 6
      ? setFilteredEventsList(eventsList)
      : setFilteredEventsList(eventsList.filter(e => filters.includes(e.resource.type)));
  }, [eventsList, filters]);

  const updateFilters = target => {
    target === 'ALL'
      ? setFilters([])
      : filters.includes(target)
      ? setFilters(filters.filter(item => item !== target))
      : setFilters([...filters, target]);
  };

  return (
    <>
      {isLoading && <Loader />}
      {isErrored && <Navigate to="/error" replace={true} />}
      <Row className="mb-3">
        <Col xs={2} className="my-2 d-flex gap-2 align-items-center">
          <ToggleButton
            key={CalendarType.Compact}
            className="display-toggle"
            type="radio"
            variant="outline-light"
            value={CalendarType.Compact}
            checked={calendarType === CalendarType.Compact}
            onClick={e => {
              e.preventDefault();
              setCalendarType(CalendarType.Compact);
            }}>
            <MdFormatAlignJustify />
          </ToggleButton>
          <ToggleButton
            key={CalendarType.Line}
            className="display-toggle"
            type="radio"
            variant="outline-light"
            value={CalendarType.Line}
            checked={calendarType === CalendarType.Line}
            onClick={e => {
              e.preventDefault();
              setCalendarType(CalendarType.Line);
            }}>
            <MdDensityMedium />
          </ToggleButton>
        </Col>
        <Col xs={10} className="my-2 d-none d-lg-flex gap-2 justify-content-end align-items-center">
          <ToggleButton
            key="ALL"
            className="type-toggle"
            type="checkbox"
            variant={`outline-light`}
            checked={filters.length === 0}
            value={'ALL'}
            onClick={e => {
              e.preventDefault();
              updateFilters('ALL');
            }}>
            Show All
          </ToggleButton>
          {Object.keys(EventTypes).map(type => {
            return (
              <ToggleButton
                key={type}
                className="type-toggle"
                type="checkbox"
                variant={`outline-${EventTypes[type].color}`}
                checked={filters.includes(type)}
                value={type}
                onClick={e => {
                  e.preventDefault();
                  updateFilters(type);
                }}>
                {EventTypes[type].name}
              </ToggleButton>
            );
          })}
        </Col>
        <Col xs={10} className="my-2 d-lg-none d-flex gap-2 justify-content-end align-items-center">
          <Dropdown>
            <Dropdown.Toggle variant="light" className="type-dropdown">
              <MdFilterList />
            </Dropdown.Toggle>

            <Dropdown.Menu className="type-dropdown-menu p-0 border-0">
              <span className="d-flex gap-1 flex-column">
                <ToggleButton
                  key="ALL"
                  className="type-toggle rounded-0 rounded-top"
                  type="checkbox"
                  variant={`outline-light`}
                  checked={filters.length === 0}
                  value={'ALL'}
                  onClick={e => {
                    e.preventDefault();
                    updateFilters('ALL');
                  }}>
                  Show All
                </ToggleButton>
                {Object.keys(EventTypes).map((type, i, arr) => {
                  if (i + 1 === arr.length)
                    return (
                      <ToggleButton
                        key={type}
                        className="type-toggle rounded-0 rounded-bottom"
                        type="checkbox"
                        variant={`outline-${EventTypes[type].color}`}
                        checked={filters.includes(type)}
                        value={type}
                        onClick={e => {
                          e.preventDefault();
                          updateFilters(type);
                        }}>
                        {EventTypes[type].name}
                      </ToggleButton>
                    );
                  else
                    return (
                      <ToggleButton
                        key={type}
                        className="type-toggle rounded-0"
                        type="checkbox"
                        variant={`outline-${EventTypes[type].color}`}
                        checked={filters.includes(type)}
                        value={type}
                        onClick={e => {
                          e.preventDefault();
                          updateFilters(type);
                        }}>
                        {EventTypes[type].name}
                      </ToggleButton>
                    );
                })}
              </span>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <EventCalendar
            key={calendarType}
            date={moment(`${date.month} ${date.year}`, 'MM YYYY').toDate()}
            events={filteredEventsList}
            calendarType={calendarType}
            setDate={setDate}
          />
        </Col>
      </Row>
    </>
  );
};

export default Home;
