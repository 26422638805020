import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout/Layout';
import Home from './Views/Home/Home';
import EventDetails from './Views/EventDetails/EventDetails';
import ErrorPage from './Views/ErrorPage/ErrorPage';

export interface AppRouterProps {}

export const AppRouter: React.FunctionComponent<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/error"
          element={
            <Layout>
              <ErrorPage fromError={true} />
            </Layout>
          }
        />
        <Route
          path="/:event_type/:event_number"
          element={
            <Layout>
              <EventDetails />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <ErrorPage fromError={false} />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
