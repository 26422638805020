import './EventLineItem.scss';
import React from 'react';
import { EventTypes } from '../../../Constants/eventtype';
import moment from 'moment';

export interface EventLineItemProps {
  event;
}

const EventLineItem: React.FunctionComponent<EventLineItemProps> = ({ event }) => {
  return (
    <div
      className={`rbc-event-item rounded d-flex flex-column bg-${EventTypes[event.resource.type].color}${
        event.resource.data.items[0].closingDate < moment().valueOf() ? ' disabled' : ''
      }`}
      title={event.title}
      onClick={e => {
        e.stopPropagation();
        window.open(`/${event.resource.type}/${event.resource.data.eventNumber}`, '_blank');
      }}>
      <span className="rbc-event-title">{event.title}</span>
      <span className="rbc-event-subtitle">
        {event.resource.data.city}, {event.resource.data.state}
      </span>
    </div>
  );
};

export default EventLineItem;
