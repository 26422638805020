export enum EventType {
  AG = 'AG',
  BH = 'BH',
  CABT = 'CABT',
  FCAT = 'FCAT',
  RLY = 'RLY',
  SCWK = 'SCWK',
}

export const EventTypes = {
  [EventType.AG]: {
    name: 'Agility',
    color: 'red',
  },
  [EventType.BH]: {
    name: 'Barn Hunt',
    color: 'orange',
  },
  [EventType.CABT]: {
    name: 'Coursing Ability Test',
    color: 'yellow',
  },
  [EventType.FCAT]: {
    name: 'FastCAT',
    color: 'green',
  },
  [EventType.RLY]: {
    name: 'Rally',
    color: 'blue',
  },
  [EventType.SCWK]: {
    name: 'Scentwork',
    color: 'purple',
  },
};
